define("discourse/plugins/chat/discourse/components/chat-message-collapser", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "ember-modifier", "discourse/components/decorated-html", "discourse/lib/dom-from-string", "discourse/lib/utilities", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat-upload", "discourse/plugins/chat/discourse/components/collapser", "discourse/plugins/discourse-lazy-videos/discourse/components/lazy-video", "discourse/plugins/chat/discourse/lib/lightbox", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template, _emberModifier, _decoratedHtml, _domFromString, _utilities, _discourseI18n, _chatUpload, _collapser, _lazyVideo, _lightbox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isCollapsible = isCollapsible;
  class ChatMessageCollapser extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    lightbox = (() => (0, _emberModifier.modifier)(element => {
      if (this.args.uploads.length > 0) {
        (0, _lightbox.default)(element.querySelectorAll("img.chat-img-upload"));
      }
    }))();
    get hasUploads() {
      return hasUploads(this.args.uploads);
    }
    get uploadsHeader() {
      let name = "";
      if (this.args.uploads.length === 1) {
        name = this.args.uploads[0].original_filename;
      } else {
        name = (0, _discourseI18n.i18n)("chat.uploaded_files", {
          count: this.args.uploads.length
        });
      }
      return (0, _template.htmlSafe)(`<span class="chat-message-collapser-link-small">${(0, _utilities.escapeExpression)(name)}</span>`);
    }
    get cookedBodies() {
      const elements = Array.prototype.slice.call((0, _domFromString.default)(this.args.cooked));
      if (hasLazyVideo(elements)) {
        return this.lazyVideoCooked(elements);
      }
      if (hasImageOnebox(elements)) {
        return this.imageOneboxCooked(elements);
      }
      if (hasImage(elements)) {
        return this.imageCooked(elements);
      }
      if (hasGallery(elements)) {
        return this.galleryCooked(elements);
      }
      return [];
    }
    lazyVideoCooked(elements) {
      return elements.reduce((acc, e) => {
        if (this.siteSettings.lazy_videos_enabled && lazyVideoPredicate(e)) {
          const getVideoAttributes = requirejs("discourse/plugins/discourse-lazy-videos/lib/lazy-video-attributes").default;
          const videoAttributes = getVideoAttributes(e);
          if (this.siteSettings[`lazy_${videoAttributes.providerName}_enabled`]) {
            const link = (0, _utilities.escapeExpression)(videoAttributes.url);
            const title = videoAttributes.title;
            const header = (0, _template.htmlSafe)(`<a target="_blank" class="chat-message-collapser-link" rel="noopener noreferrer" href="${link}">${title}</a>`);
            acc.push({
              header,
              body: e.outerHTML,
              videoAttributes,
              needsCollapser: true
            });
          } else {
            acc.push({
              body: e.outerHTML,
              needsCollapser: false
            });
          }
        } else {
          acc.push({
            body: e.outerHTML,
            needsCollapser: false
          });
        }
        return acc;
      }, []);
    }
    imageOneboxCooked(elements) {
      return elements.reduce((acc, e) => {
        if (imageOneboxPredicate(e)) {
          let link = animatedImagePredicate(e) ? e.firstChild.src : e.firstElementChild.href;
          link = (0, _utilities.escapeExpression)(link);
          const header = (0, _template.htmlSafe)(`<a target="_blank" class="chat-message-collapser-link-small" rel="noopener noreferrer" href="${link}">${link}</a>`);
          acc.push({
            header,
            body: e.outerHTML,
            needsCollapser: true
          });
        } else {
          acc.push({
            body: e.outerHTML,
            needsCollapser: false
          });
        }
        return acc;
      }, []);
    }
    imageCooked(elements) {
      return elements.reduce((acc, e) => {
        if (imagePredicate(e)) {
          const link = (0, _utilities.escapeExpression)(e.firstElementChild.src);
          const alt = (0, _utilities.escapeExpression)(e.firstElementChild.alt);
          const header = (0, _template.htmlSafe)(`<a target="_blank" class="chat-message-collapser-link-small" rel="noopener noreferrer" href="${link}">${alt || link}</a>`);
          acc.push({
            header,
            body: e.outerHTML,
            needsCollapser: true
          });
        } else {
          acc.push({
            body: e.outerHTML,
            needsCollapser: false
          });
        }
        return acc;
      }, []);
    }
    galleryCooked(elements) {
      return elements.reduce((acc, e) => {
        if (galleryPredicate(e)) {
          const link = (0, _utilities.escapeExpression)(e.firstElementChild.href);
          const title = (0, _utilities.escapeExpression)(e.firstElementChild.firstElementChild.textContent);
          e.firstElementChild.removeChild(e.firstElementChild.firstElementChild);
          const header = (0, _template.htmlSafe)(`<a target="_blank" class="chat-message-collapser-link-small" rel="noopener noreferrer" href="${link}">${title}</a>`);
          acc.push({
            header,
            body: e.outerHTML,
            needsCollapser: true
          });
        } else {
          acc.push({
            body: e.outerHTML,
            needsCollapser: false
          });
        }
        return acc;
      }, []);
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-message-collapser">
          {{#if this.hasUploads}}
            <DecoratedHtml
              @html={{htmlSafe @cooked}}
              @decorate={{@decorate}}
              @className="chat-cooked"
            />
    
            <Collapser
              @header={{this.uploadsHeader}}
              @onToggle={{@onToggleCollapse}}
            >
              <div class="chat-uploads" {{this.lightbox}}>
                {{#each @uploads as |upload|}}
                  <ChatUpload @upload={{upload}} />
                {{/each}}
              </div>
            </Collapser>
          {{else}}
            {{#each this.cookedBodies as |cooked|}}
              {{#if cooked.needsCollapser}}
                <Collapser
                  @header={{cooked.header}}
                  @onToggle={{@onToggleCollapse}}
                >
                  {{#if cooked.videoAttributes}}
                    <div class="chat-message-collapser-lazy-video">
                      <LazyVideo @videoAttributes={{cooked.videoAttributes}} />
                    </div>
                  {{else}}
                    <DecoratedHtml
                      @html={{htmlSafe cooked.body}}
                      @decorate={{@decorate}}
                      @className="chat-cooked"
                    />
                  {{/if}}
                </Collapser>
              {{else}}
                <DecoratedHtml
                  @html={{htmlSafe cooked.body}}
                  @decorate={{@decorate}}
                  @className="chat-cooked"
                />
              {{/if}}
            {{/each}}
          {{/if}}
        </div>
      
    */
    {
      "id": "m6f8svIo",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-collapser\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasUploads\"]],[[[1,\"        \"],[8,[32,0],null,[[\"@html\",\"@decorate\",\"@className\"],[[28,[32,1],[[30,1]],null],[30,2],\"chat-cooked\"]],null],[1,\"\\n\\n        \"],[8,[32,2],null,[[\"@header\",\"@onToggle\"],[[30,0,[\"uploadsHeader\"]],[30,3]]],[[\"default\"],[[[[1,\"\\n          \"],[11,0],[24,0,\"chat-uploads\"],[4,[30,0,[\"lightbox\"]],null,null],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,4]],null]],null],null,[[[1,\"              \"],[8,[32,3],null,[[\"@upload\"],[[30,5]]],null],[1,\"\\n\"]],[5]],null],[1,\"          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[42,[28,[31,2],[[28,[31,2],[[30,0,[\"cookedBodies\"]]],null]],null],null,[[[41,[30,6,[\"needsCollapser\"]],[[[1,\"            \"],[8,[32,2],null,[[\"@header\",\"@onToggle\"],[[30,6,[\"header\"]],[30,3]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,6,[\"videoAttributes\"]],[[[1,\"                \"],[10,0],[14,0,\"chat-message-collapser-lazy-video\"],[12],[1,\"\\n                  \"],[8,[32,4],null,[[\"@videoAttributes\"],[[30,6,[\"videoAttributes\"]]]],null],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],[[[1,\"                \"],[8,[32,0],null,[[\"@html\",\"@decorate\",\"@className\"],[[28,[32,1],[[30,6,[\"body\"]]],null],[30,2],\"chat-cooked\"]],null],[1,\"\\n\"]],[]]],[1,\"            \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[32,0],null,[[\"@html\",\"@decorate\",\"@className\"],[[28,[32,1],[[30,6,[\"body\"]]],null],[30,2],\"chat-cooked\"]],null],[1,\"\\n\"]],[]]]],[6]],null]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@cooked\",\"@decorate\",\"@onToggleCollapse\",\"@uploads\",\"upload\",\"cooked\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message-collapser.js",
      "scope": () => [_decoratedHtml.default, _template.htmlSafe, _collapser.default, _chatUpload.default, _lazyVideo.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatMessageCollapser;
  function lazyVideoPredicate(e) {
    return e.classList.contains("lazy-video-container");
  }
  function hasLazyVideo(elements) {
    return elements.some(e => lazyVideoPredicate(e));
  }
  function animatedImagePredicate(e) {
    return e.firstChild && e.firstChild.nodeName === "IMG" && e.firstChild.classList.contains("animated") && e.firstChild.classList.contains("onebox");
  }
  function externalImageOnebox(e) {
    return e.firstElementChild && e.firstElementChild.nodeName === "A" && e.firstElementChild.classList.contains("onebox") && e.firstElementChild.firstElementChild && e.firstElementChild.firstElementChild.nodeName === "IMG";
  }
  function imageOneboxPredicate(e) {
    return animatedImagePredicate(e) || externalImageOnebox(e);
  }
  function hasImageOnebox(elements) {
    return elements.some(e => imageOneboxPredicate(e));
  }
  function hasUploads(uploads) {
    return uploads?.length > 0;
  }
  function imagePredicate(e) {
    return e.nodeName === "P" && e.firstElementChild && e.firstElementChild.nodeName === "IMG" && !e.firstElementChild.classList.contains("emoji");
  }
  function hasImage(elements) {
    return elements.some(e => imagePredicate(e));
  }
  function galleryPredicate(e) {
    return e.firstElementChild && e.firstElementChild.nodeName === "A" && e.firstElementChild.firstElementChild && e.firstElementChild.firstElementChild.classList.contains("outer-box");
  }
  function hasGallery(elements) {
    return elements.some(e => galleryPredicate(e));
  }
  function isCollapsible(cooked, uploads) {
    const elements = Array.prototype.slice.call((0, _domFromString.default)(cooked));
    return hasLazyVideo(elements) || hasImageOnebox(elements) || hasUploads(uploads) || hasImage(elements) || hasGallery(elements);
  }
});